import { useCallback, useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Accordion } from "reactstrap";
import FormButton from "components/button/FormButton";
import FormCard from "components/form-card/FormCard";
import { PRELIMINARY_INFO } from "config/registration-form/preliminaryInfo";
import { COMPANY_INFO } from "config/registration-form/companyInfo";
import { REPRESENTATIVE } from "config/registration-form/representative";
import {
  POINT_HOURS_INFO,
  POINT_INFO,
} from "config/registration-form/pointInfo";
import { APPROVMENTS } from "config/registration-form/approvments";
import FormCardError from "components/form-card/FormCardError";
import { getRequest } from "helper/apiCaller";
import { RegistrationFormSchema } from "config/registration-form/validationSchema/validationSchema";
import { initialValues } from "config/registration-form/initial/initial";
import Representatives from "components/forms/registration_form/Representatives";
import { REGISTRATION_FORM_URL, URLS } from "config/registration-form/urls";
import { TInputProps, TOption } from "types/inputProps";

import axios from "axios";
import { getValue } from "helper/functions";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  TRegistrationForm,
  TRepresentative,
  TVoivodeship,
} from "components/forms/registration_form/types";
import SuccessModal from "components/modals/success-modal/SuccessModal";
import TSLayout from "layouts/TSLayout";

const RegistrationForm = () => {
  const { t } = useTranslation();
  document.title = t("FORM_PDF.DOCUMENT_TITLE");
  const [activeTab, setActiveTab] = useState(0);
  const [voivodeships, setVoivodeships] = useState<TOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [applicationNumber, setApplicationNumber] = useState("");
  const [initValues, setInitValues] = useState(initialValues);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (
    values: TRegistrationForm,
    resetForm: Function
  ) => {
    setIsLoading(true);

    let tempPoint = values.point;
    let tempHours = values.hours;
    if (!values.hours.other) {
      tempHours = {
        ...tempHours,
        monday: values.hours.mon_fry,
        tuesday: values.hours.mon_fry,
        wednesday: values.hours.mon_fry,
        thursday: values.hours.mon_fry,
        friday: values.hours.mon_fry,
      };
    }
    if (tempPoint.address.sameThenCompanyAddress) {
      tempPoint = {
        ...tempPoint,
        address: {
          ...tempPoint.address,
          state: values.registeredAddress.state,
          province: values.registeredAddress.province,
          commune: values.registeredAddress.commune,
          city: values.registeredAddress.city,
          postCode: values.registeredAddress.postCode,
          street: values.registeredAddress.street,
          houseNumber: values.registeredAddress.houseNumber,
          localNumber: values.registeredAddress.localNumber,
        },
      };
    }
    tempPoint = { ...tempPoint, hours: tempHours };
    if (tempPoint.gps !== "") {
      tempPoint = {
        ...tempPoint,
        gps: {
          latitude:
            typeof values.point.gps === "string"
              ? parseFloat(values.point.gps.split(",")[0])
              : null,
          longitude:
            typeof values.point.gps === "string"
              ? parseFloat(values.point.gps.split(",")[1].trim())
              : null,
        },
      };
    } else {
      const searchString = `${
        tempPoint.address.state.label
      },${tempPoint.address.province.label
        .split("(")[0]
        .trim()},${tempPoint.address.commune.label
        .split("(")[0]
        .trim()},${tempPoint.address.city.label.split("(")[0].trim()},${
        tempPoint.address.street?.label.split("(")[0].trim() || ""
      },${tempPoint.address.houseNumber}`;
      await axios
        .get(
          `https://nominatim.openstreetmap.org/search?q=${searchString}&accept-language=pl&format=json`
        )
        .then((response) => {
          if (response.data[0]) {
            tempPoint = {
              ...tempPoint,
              gps: {
                latitude: response.data[0]?.lat ?? null,
                longitude: response.data[0]?.lon ?? null,
              },
            };
          } else {
            tempPoint = {
              ...tempPoint,
              gps: {
                latitude: null,
                longitude: null,
              },
            };
          }
        })
        .catch(() => {
          tempPoint = {
            ...tempPoint,
            gps: {
              latitude: null,
              longitude: null,
            },
          };
        });
    }
    const type = values.companyType.value
      ? values.companyType.value
      : values.companyType;
    const localRepresentatives: TRepresentative[] = [];
    values.representatives.forEach((representative: TRepresentative) => {
      if (type === "CAPITAL_AND_PARTNERSHIP" || representative.pesel === "") {
        localRepresentatives.push({
          ...representative,
          pesel: null,
          identityCardNumber: null,
        });
      } else {
        localRepresentatives.push({ ...representative });
      }
    });

    let body = {
      company: {
        type: type,
        nip: values.nip,
        regon: values.regon,
        name: values.companyName,
      },
      registeredAddress: {
        ...values.registeredAddress,
        street:
          values.registeredAddress.street?.label === ""
            ? null
            : values.registeredAddress.street,
      },
      companyAddressSameThanRegistered:
        values.companyAddress?.sameThenCompanyAddress || false,
      companyAddress: values.companyAddress?.sameThenCompanyAddress
        ? null
        : values.companyAddress,
      krsInformation:
        values.companyType.value === "JDG" ||
        values.companyType.value === "CIVIL"
          ? null
          : {
              krs: values.krs,
              shareCapital: values.capital,
              courtName: values.court,
              courtDepartment: values.courtDepartment,
            },
      representatives: localRepresentatives,
      point: {
        ...tempPoint,
        address: {
          ...tempPoint.address,
          street:
            tempPoint.address.street?.label === ""
              ? null
              : tempPoint.address.street,
        },
      },
      approvement: values.approvement,
    };
    let token = "";
    if (executeRecaptcha) {
      token = await executeRecaptcha("getCompoantData");
    }

    axios
      .post(
        `${REGISTRATION_FORM_URL}/api-form/save/sal`,
        { ...body },
        { headers: { recaptcha: token } }
      )
      .then((res) => {
        toast.success(t("FORM_PDF.FORM.SUCCESS"));
        localStorage.removeItem("store");
        setInitValues(initialValues);
        resetForm();
        setApplicationNumber(res.data.hash);
      })
      .catch(() => toast.error(t("FORM_PDF.FORM.ERROR")))
      .finally(() => setIsLoading(false));
  };

  const getVoivodeships = useCallback(() => {
    getRequest({
      url: URLS.voivodeships,
      then: (data: TVoivodeship[]) => {
        let options: TOption[] = [];
        data.forEach((item: TVoivodeship) => {
          options.push({ value: item.id, label: item.name });
        });
        setVoivodeships(options);
      },
    });
  }, []);

  useEffect(() => {
    getVoivodeships();
  }, [getVoivodeships]);

  useEffect(() => {
    const store = localStorage.getItem("store");
    if (store) {
      setInitValues(JSON.parse(store));
    }
  }, []);

  const [open, setOpen] = useState("0");
  const toggle = (id: string) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const checkTabErrors = (list: any, errors: any, touched: any) => {
    let flag = false;
    if (list) {
      list.forEach((item: any) => {
        if (errors[item.name] && touched[item.name]) {
          flag = true;
          return;
        }
      });
    } else {
      flag = checkRepresentativesErorr(errors, touched);
    }
    return flag;
  };

  const fastCheckErrors = (errors: any, touched: any, nameToCheck: string) => {
    return (
      errors[nameToCheck] !== undefined && touched[nameToCheck] !== undefined
    );
  };

  const checkRepresentativesErorr = (errors: any, touched: any) => {
    let flag = false;
    if (
      errors.representatives === undefined ||
      touched.representatives === undefined
    )
      return false;
    errors.representatives.forEach((representative: any, index: number) => {
      REPRESENTATIVE.forEach((item) => {
        if (
          representative?.[item.name] &&
          touched.representatives[index]?.[item.name]
        )
          flag = true;
      });
    });
    return flag;
  };

  const addVoivodeshipsOptions = (list: TInputProps[]) => {
    const index = list.findIndex((item) => item.data === "voivodeships");
    if (index !== -1) {
      list[index] = { ...list[index], options: voivodeships };
    }
    return list;
  };

  const FORM_GROUPS = [
    {
      inputs: PRELIMINARY_INFO,
      title: t("FORM_PDF.FORM.PRELIMINARY_INFO.TITLE"),
    },
    {
      inputs: COMPANY_INFO.firstPart,
      title: t("FORM_PDF.FORM.COMPANY_INFO.MAIN"),
    },
    {
      inputs: addVoivodeshipsOptions(COMPANY_INFO.secondPart),
      title: t("FORM_PDF.FORM.COMPANY_INFO.REGISTRATION_ADDRESS"),
      hasError: "registeredAddress",
    },
    {
      inputs: addVoivodeshipsOptions(COMPANY_INFO.thirdPart),
      title: t("FORM_PDF.FORM.COMPANY_INFO.COMPANY_ADDRESS"),
    },
    {
      inputs: COMPANY_INFO.fourthPart,
      title: t("FORM_PDF.FORM.COMPANY_INFO.KRS_TITLE"),
      visibleConditions: [
        { label: "companyType", value: "CAPITAL_AND_PARTNERSHIP" },
      ],
    },
    {
      title: t("FORM_PDF.FORM.REPRESENTATIVE.TITLE"),
      type: "representative",
    },
    {
      inputs: addVoivodeshipsOptions(POINT_INFO),
      title: t("FORM_PDF.FORM.POINT_INFORMATION.TITLE"),
      hasError: "point",
    },
    {
      inputs: POINT_HOURS_INFO,
      title: t("FORM_PDF.FORM.POINT_INFORMATION.HOURS.TITLE"),
      hasError: "hours",
    },
    {
      title: t("FORM_PDF.FORM.PESONAL_DATA.HEADER"),
      type: "personalData",
    },
    {
      inputs: APPROVMENTS,
      title: t("FORM_PDF.FORM.APPROVMENTS.TITLE"),
      hasError: "approvement",
    },
  ];

  const isVisible = (
    conditions: { label: string; value: any }[],
    values: any
  ) => {
    let flag = conditions.length === 0;
    conditions.forEach((condition) => {
      const valueToCheck =
        values[condition.label]?.value ||
        values[condition.label] ||
        getValue(values, condition.label);
      if (valueToCheck === condition.value) flag = true;
      return;
    });
    return flag;
  };

  const genearteText = (text: string) => {
    const texts = text.split("<br />");
    if (texts.length !== 1) {
      return (
        <div>
          {texts[0]}
          <br />
          <ol type="a">
            {texts.slice(1).map((element, index) => (
              <li key={index}>{element}</li>
            ))}
          </ol>
        </div>
      );
    } else {
      return <p>{text}</p>;
    }
  };

  const PERSONAL_DATA = [
    {
      TITLE: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_1.TITLE"),
      CONTENT: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_1.CONTENT"),
    },
    {
      TITLE: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_2.TITLE"),
      CONTENT: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_2.CONTENT"),
    },
    {
      TITLE: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_3.TITLE"),
      CONTENT: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_3.CONTENT"),
    },
    {
      TITLE: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_4.TITLE"),
      CONTENT: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_4.CONTENT"),
    },
    {
      TITLE: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_5.TITLE"),
      CONTENT: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_5.CONTENT"),
    },
    {
      TITLE: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_6.TITLE"),
      CONTENT: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_6.CONTENT"),
    },
    {
      TITLE: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_7.TITLE"),
      CONTENT: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_7.CONTENT"),
    },
    {
      TITLE: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_8.TITLE"),
      CONTENT: t("FORM_PDF.FORM.PESONAL_DATA.CONTENT.POINT_8.CONTENT"),
    },
  ];

  const getCustomComponent = (type: string, formik: any) => {
    switch (type) {
      case "representative":
        return (
          <Representatives
            formik={formik}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            voivodeships={voivodeships}
          />
        );
      case "personalData":
        return (
          <div>
            <div className="text-center fs-4 m-3">
              <strong>{t("FORM_PDF.FORM.PESONAL_DATA.TITLE")}</strong>
            </div>
            <div className="m-2">
              {t("FORM_PDF.FORM.PESONAL_DATA.SUB_TITLE")}
            </div>
            <div>
              <ol>
                {PERSONAL_DATA.map((item, index) => (
                  <li key={index}>
                    <div>
                      <strong>{item.TITLE}</strong>
                    </div>
                    {genearteText(item.CONTENT)}
                  </li>
                ))}
              </ol>
            </div>
            <div style={{ fontSize: "10px" }}>
              {t("FORM_PDF.FORM.PESONAL_DATA.FOOTER")}
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const checkIsSomeErrors = () => {
    return (
      document.getElementsByClassName("form-card__accordion_item--error")
        .length !== 0
    );
  };

  return (
    <TSLayout>
      <div className="mini-container ts-layout">
        <h2 className="totalizator-title aos-init aos-animate">
          Formularz zgłoszeniowy
        </h2>
      </div>
      <div className="mini-container ts-layout ts-layout--bg">
        <SuccessModal
          applicationNumber={applicationNumber}
          closeModal={() => setApplicationNumber("")}
        />
        <div className="mini-container__content">
          <Formik
            initialValues={initValues}
            onSubmit={(values: any, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
            validationSchema={RegistrationFormSchema}
            enableReinitialize
          >
            {(formik: any) => (
              <Form>
                <Accordion
                  open={open}
                  {...{
                    toggle: (accordionItem: string) => {
                      toggle(accordionItem);
                      localStorage.setItem(
                        "store",
                        JSON.stringify(formik.values)
                      );
                    },
                  }}
                >
                  {FORM_GROUPS.map((group, index) => (
                    <FormCard
                      key={index}
                      inputs={
                        Array.isArray(group.inputs) ? group.inputs : undefined
                      }
                      partsInput={
                        Array.isArray(group.inputs) ? undefined : group.inputs
                      }
                      hasError={
                        group.hasError
                          ? fastCheckErrors(
                              formik.errors,
                              formik.touched,
                              group.hasError
                            )
                          : checkTabErrors(
                              group.inputs,
                              formik.errors,
                              formik.touched
                            )
                      }
                      index={`${index}`}
                      active={open === `${index}`}
                      title={group.title}
                      isVisible={isVisible(
                        group.visibleConditions || [],
                        formik.values
                      )}
                    >
                      {getCustomComponent(group.type || "", formik) || ""}
                    </FormCard>
                  ))}
                </Accordion>
                <FormCardError visible={checkIsSomeErrors()}>
                  <div>{`${t("FORM_PDF.ERROR.PART_1")} `}</div>
                </FormCardError>
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <FormButton
                    isLoading={isLoading}
                    type="submit"
                    title={t("FORM_PDF.FORM.BUTTON")}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </TSLayout>
  );
};

export default RegistrationForm;
