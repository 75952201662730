const TERYT_URL = "https://otworzkolekturelotto.totalizator.pl/teryt-api/";

export const REGISTRATION_FORM_URL = window.location.href.includes("localhost")
  ? "https://ts-dro-formgenerator-app.azurewebsites.net"
  : "";

export const URLS = {
  voivodeships: `${TERYT_URL}voivodeships`,
  voivodeship: `${TERYT_URL}voivodeship/`,
  counties: `${TERYT_URL}counties`,
  county: `${TERYT_URL}county/`,
  communities: `${TERYT_URL}communities`,
  community: `${TERYT_URL}community/`,
  cities: `${TERYT_URL}cities`,
  city: `${TERYT_URL}city/`,
  streets: `${TERYT_URL}streets`,
  verification: `${TERYT_URL}verification`,
  GET_PDF: `${REGISTRATION_FORM_URL}/api-form/pdf`,
};

export const REGISTRATION_FORM_CMS_API_URL = window.location.href.includes(
  "https://otworzkolekturelotto.totalizator.pl"
)
  ? "https://www.totalizator.pl/api-form/cms/dispatch?url=/"
  : "https://staging.devtotalizator.pl/api-form/cms/dispatch?url=/";
export const MAIN_TS_URL = "https://www.totalizator.pl";
